<template>
  <div>
    <el-form :inline="true" :model="form" ref="ruleForm" label-width="100px">
      <el-form-item class="form-item" label="课程模板名称" prop="template_name">
        <el-input
          v-model="form.template_name"
          placeholder="请输入课程模板名称"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="用户手机" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="视频上传时间">
        <el-date-picker
          v-model="value"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 260px"
          @change="handChangeTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item" label="视频状态" prop="status">
        <el-select v-model="form.status" placeholder="全部" clearable>
          <el-option label="未审核" value="未审核"></el-option>
          <el-option label="已审核" value="已审核"></el-option>
          <el-option label="审核不通过" value="审核不通过"></el-option>
          <el-option label="已删除" value="已删除"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-content">
      <div class="table-title">活动模板列表</div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          fixed
          prop="id"
          label="编号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="用户账号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="template_name" label="模板名称" width="250">
        </el-table-column>
        <el-table-column label="缩略图" width="321" align="center">
          <template #default="scope">
            <el-image
              style="width: 100%; height: 200px"
              :src="scope.row.picture_url"
              fit="contain"
              @click="handVideo(scope.row.media_url)"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="upload_time"
          label="发布时间"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="likes"
          label="点赞基数"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="视频状态"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template #default="scope">
            <el-button
              v-if="scope.row.status === '未审核' && type === 3"
              type="text"
              size="small"
              @click="handClick(scope.row.id)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
    </div>
    <el-dialog
      width="400px"
      title="视频预览"
      v-model="dialogTableVisible"
      @close="handClose"
    >
      <video v-if="isVideo" :src="video_url" class="video" controls></video>
    </el-dialog>
    <el-dialog width="500px" title="视频审核" v-model="show">
      <el-form :model="auditForm" label-width="100px">
        <el-form-item label="审核：">
          <el-radio-group v-model="auditForm.status">
            <el-radio label="通过"></el-radio>
            <el-radio label="不通过"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="auditForm.audit_remarks"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="handSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import { getActivityList, auditUmishow } from "@/api/apiList/operate-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        template_name: "",
        phone: "",
        start_time: "",
        end_time: "",
        status: "",
        page: 1,
        pageSize: 10,
      },
      value: "",
      tableData: [],
      loading: true,
      pageSize: 10,
      currentPage: 1,
      total: 10,
      dialogTableVisible: false,
      video_url: "",
      show: false,
      auditForm: {
        id: "",
        status: "",
        audit_remarks: "",
      },
      isVideo: false,
      type: "",
    };
  },
  methods: {
    handChangeTime(e) {
      let start_m =
        new Date(e[0]).getMonth() > 8
          ? new Date(e[0]).getMonth() + 1
          : "0" + (new Date(e[0]).getMonth() + 1);
      let start_d =
        new Date(e[0]).getDate() > 9
          ? new Date(e[0]).getDate()
          : "0" + new Date(e[0]).getDate();
      let end_m =
        new Date(e[1]).getMonth() > 8
          ? new Date(e[1]).getMonth() + 1
          : "0" + (new Date(e[1]).getMonth() + 1);
      let end_d =
        new Date(e[1]).getDate() > 9
          ? new Date(e[1]).getDate()
          : "0" + new Date(e[1]).getDate();
      this.form.start_time =
        new Date(e[0]).getFullYear() + "-" + start_m + "-" + start_d;
      this.form.end_time =
        new Date(e[1]).getFullYear() + "-" + end_m + "-" + end_d;
    },
    onSubmit() {
      this.loading = true;
      this.handList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.value = "";
      this.form.start_time = "";
      this.form.end_time = "";
    },
    handVideo(e) {
      this.isVideo = true;
      this.dialogTableVisible = true;
      this.video_url = e;
    },
    handClick(e) {
      this.auditForm.id = e;
      this.show = true;
    },
    handSubmit() {
      auditUmishow(this.auditForm).then((res) => {
        if (res.result === "200") {
          this.show = false;
          this.handList();
        }
      });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    handClose() {
      this.isVideo = false;
    },
    handList() {
      getActivityList(this.form).then((res) => {
        if (res.result === "200") {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    this.handList();
  },
};
</script>

<style>
.video {
  width: 100%;
}
</style>
