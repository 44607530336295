import { get, post } from "../request";

// 返现用户列表
export const geWktList = (params) => get("/wkactivity/geWktList", params);

// 验收问卷
export const checkPaper = (params) => post("/wkactivity/checkPaper", params);

// 广告列表
export const advertisementList = (params) => get("/advertisement/list", params);

// 上传广告图
export const followreadUploadtonas = (params) =>
  post("/followread/uploadtonas", params);

// 编辑广告
export const advertisementEdit = (params) =>
  post("/advertisement/edit", params);

// 反馈列表
export const suggestionList = (params) => get("/suggestion/list", params);

// 反馈详情
export const suggestionDetail = (params) => get("/suggestion/detail", params);

// 反馈处理
export const suggestionEdit = (params) => post("/suggestion/edit", params);

// 课程主题列表
export const themeTree = (params) => get("/userumishow/themeTree", params);

// 课程模板列表
export const getClassList = (params) =>
  get("/userumishow/getClassList", params);

// 活动模板列表
export const getActivityList = (params) =>
  get("/userumishow/getActivityList", params);

// 课程活动审核
export const auditUmishow = (params) =>
  post("/userumishow/auditUmishow", params);

// Expression视频列表
export const getExpressionsList = (params) =>
  get("/userumishow/getExpressionsList", params);

// Expression视频审核
export const auditExpressions = (params) =>
  post("/userumishow/auditExpressions", params);
