<template>
  <el-pagination
    class="pagination-content"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :page-sizes="[10, 20]"
    :page-size="pageSize"
    :current-page="currentPage"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    handleSizeChange(val) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit("handSizeChange", val);
    },
    handleCurrentChange(val) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit("handCurrentChange", val);
    },
  },
};
</script>

<style>
.pagination-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}
</style>
